<template>
  <v-row>
    <v-col
      cols="12"
      md="4"
    >
      <v-card>
        <v-img
          height="200"
          src="https://source.unsplash.com/300x300/?building"
        >
          <template v-slot:placeholder>
            <v-skeleton-loader type="image" />
          </template>
        </v-img>

        <v-skeleton-loader
          :loading="loading"
          class="mx-auto"
          type="article"
        >
          <v-card-title class="d-flex justify-space-between align-center">
            <span class="font-weight-bold">{{ organizationDetail.name || 'No data' }}</span>
            <v-btn
              icon
              @click="$refs.formModal.dialog=true"
            >
              <v-icon>mdi-cog</v-icon>
            </v-btn>
            <OrganizationForm
              ref="formModal"
              :item="organizationDetail"
              @reload-data="getOrganizationDetail()"
            />
          </v-card-title>

          <v-card-text>
            <div>{{ organizationDetail.description }}</div>
          </v-card-text>
        </v-skeleton-loader>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      md="8"
    >
      <v-card class="mb-5">
        <v-tabs
          v-model="tab"
          grow
        >
          <v-tab to="subscriptions">
            Subscription
          </v-tab>
          <v-tab to="users">
            Users
          </v-tab>
          <v-tab to="devices">
            Devices
          </v-tab>
        </v-tabs>
      </v-card>

      <v-tabs-items
        v-model="tab"
        touchless
      >
        <v-tab-item
          value="subscriptions"
          class="background_color"
        >
          <OrganizationDetailSubscription
            ref="subscriptionTab"
            :organization-id="organizationId"
            :active-subscription="organizationDetail.active_subscription || {}"
            :plan-usages="organizationDetail.plan_usages || {}"
            :loading="loading"
          />
        </v-tab-item>
        <v-tab-item value="users">
          <OrganizationDetailUser
            ref="userTab"
            :organization-id="organizationId"
          />
        </v-tab-item>
        <v-tab-item value="devices">
          <OrganizationDetailDevice
            ref="deviceTab"
            :organization-id="organizationId"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>

<script>
import { handleDetailError } from '../../utils/utils';
import OrganizationDetailSubscription from './OrganizationDetailSubscription.vue';
import OrganizationDetailUser from './OrganizationDetailUser.vue';
import OrganizationDetailDevice from './OrganizationDetailDevice.vue';
import OrganizationForm from './OrganizationForm.vue';

export default {
  name: 'OrganizationDetail',
  components: {
    OrganizationDetailSubscription,
    OrganizationDetailUser,
    OrganizationDetailDevice,
    OrganizationForm,
  },

  data() {
    return {
      organizationDetail: {},
      loading: false,
      tab: null,
    };
  },

  computed: {
    organizationId() {
      return Number(this.$route.params.id);
    },

    tabRoute() {
      return this.$route.params.tab;
    },
  },

  watch: {
    tabRoute: {
      immediate: true,
      handler(route) {
        setTimeout(() => {
          if (route === 'users') {
            // re-fetch users when tab visited
            this.$refs.userTab.getUsers();
          } else if (route === 'devices') {
            // re-fetch users when tab visited
            this.$refs.deviceTab.getDevices();
          }
        }, 0);
      },
    },

    // handle router params changes
    organizationId(val) {
      this.getOrganizationDetail();
      if (this.tabRoute !== 'subscriptions') {
        this.$router.push(`/organizations/${val}`);
      }
    },
  },

  created() {
    this.getOrganizationDetail();
  },

  methods: {
    async getOrganizationDetail() {
      this.loading = true;
      try {
        const result = await this.$api.getOrganizationDetail(this.organizationId);
        this.organizationDetail = result.data.data;
      } catch (error) {
        handleDetailError(error, this);
      }
      this.loading = false;
    },
  },
};
</script>
