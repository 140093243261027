<template>
  <v-row>
    <v-col
      class="py-0"
      cols="12"
      md="6"
    >
      <v-card class="mb-5">
        <v-skeleton-loader
          :loading="loading"
          class="mx-auto"
          type="list-item-avatar-two-line@2"
        >
          <v-list two-line>
            <v-list-item
              v-for="(s, index) in subscriptionItems"
              :key="`subscription-${index}`"
            >
              <v-list-item-avatar>
                <v-icon
                  color="grey lighten-1"
                  v-text="s.icon"
                />
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-subtitle v-text="s.text" />
                <v-list-item-title>
                  <v-chip
                    v-if="s.value == 'active'"
                    color="success"
                    label
                    text-color="white"
                    small
                  >
                    <v-icon
                      left
                      small
                    >
                      mdi-check-circle
                    </v-icon>
                    Active
                  </v-chip>
                  <span v-else>{{ s.value || 'No subscription' }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-skeleton-loader>
      </v-card>
    </v-col>
    <v-col
      class="py-0"
      cols="12"
      md="6"
    >
      <v-card>
        <v-skeleton-loader
          :loading="loading"
          class="mx-auto"
          type="list-item-avatar-three-line@5"
        >
          <template v-if="activeSubscription.plan">
            <v-list
              two-line
              class="pr-3"
            >
              <v-list-item
                v-for="(s, index) in planItems"
                :key="`plan-${index}`"
              >
                <v-list-item-avatar>
                  <v-icon
                    color="grey lighten-1"
                    v-text="s.icon"
                  />
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-subtitle v-text="s.text" />
                  <v-list-item-title v-if="s.usage || s.usage == 0">
                    <span>{{ `${s.usage} / ${s.value}` }}</span>
                    <small class="grey--text"> {{ `(${valueToPercent(s.usage,s.value)} %)` }} </small>
                  </v-list-item-title>
                  <v-list-item-title
                    v-else
                    v-text="s.value"
                  />
                  <v-progress-linear
                    v-if="s.usage || s.usage == 0"
                    class="mt-1"
                    :color="s.usage>=s.value ? 'error' : 'primary'"
                    :value="valueToPercent(s.usage,s.value)"
                  />
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </template>

          <v-alert
            v-else
            outlined
            text
            type="warning"
            :value="true"
          >
            You have no active subscription
          </v-alert>
        </v-skeleton-loader>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { floatPrecision } from '../../utils/utils';

export default {
  name: 'OrganizationDetailSubscription',
  props: {
    organizationId: {
      type: Number,
      required: true,
    },
    activeSubscription: {
      type: Object,
      required: true,
    },
    planUsages: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {
    };
  },

  computed: {
    subscriptionItems() {
      return [
        {
          text: 'Subscription Status',
          value: this.activeSubscription.status,
          icon: 'mdi-credit-card-outline',
        },
        {
          text: 'End Date',
          value: this.$options.filters.formatDate(this.activeSubscription.ends_at),
          icon: 'mdi-calendar-clock',
        },

      ];
    },

    planItems() {
      return [
        {
          text: 'Plan',
          value: this.activeSubscription.plan.name,
          icon: 'mdi-cash',
        },
        {
          text: 'Users',
          value: this.activeSubscription.plan.max_users,
          icon: 'mdi-account-group',
          usage: this.planUsages.users_count || 0,
        },
        {
          text: 'Devices',
          value: this.activeSubscription.plan.max_devices,
          icon: 'mdi-developer-board',
          usage: this.planUsages.devices_count || 0,
        },
        {
          text: 'Dashboards',
          value: this.activeSubscription.plan.max_dashboards,
          icon: 'mdi-monitor-dashboard',
          usage: this.planUsages.dashboards_count || 0,
        },
        {
          text: 'Schedules',
          value: this.activeSubscription.plan.max_schedules,
          icon: 'mdi-calendar-refresh',
          usage: this.planUsages.schedules_count || 0,
        },
        {
          text: 'Max widgets per dashboard',
          value: this.activeSubscription.plan.max_widgets,
          icon: 'mdi-widgets',
        },
        {
          text: 'Max uplinks',
          value: `${this.activeSubscription.plan.max_uplinks} days`,
          icon: 'mdi-satellite-uplink',
        },
      ];
    },
  },

  methods: {
    valueToPercent(usage, max) {
      return floatPrecision((usage / max) * 100);
    },
  },

};
</script>
