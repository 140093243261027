<template>
  <v-card :loading="loading">
    <v-skeleton-loader
      :loading="loading"
      type="list-item-avatar-two-line"
      max-width="25%"
    >
      <v-list two-line>
        <v-list-item
          v-for="(d, index) in deviceItems"
          :key="index"
        >
          <v-list-item-avatar>
            <v-icon
              color="grey lighten-1"
              v-text="d.icon"
            />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-subtitle v-text="d.text" />
            <v-list-item-title v-text="d.value" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-skeleton-loader>

    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left min-width-10">
              Name
            </th>
            <th class="text-left min-width-10">
              Device ID
            </th>
            <th class="text-left min-width-10">
              Type
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="device in devices.slice(0,5)"
            :key="device.id"
          >
            <td>{{ device.name }}</td>
            <td>{{ device.device_id }}</td>
            <td>{{ underscoreToSpace(device.type) }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-card-actions class="d-flex justify-center">
      <v-btn
        block
        text
        color="primary"
        :to="devicesPath"
      >
        View All Devices
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { underscoreToSpace } from '../../utils/utils';

export default {
  name: 'OrganizationDetailDevice',
  props: {
    organizationId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      devices: [],
      deviceMeta: {},
    };
  },

  computed: {
    deviceItems() {
      return [
        {
          text: 'Total Device',
          value: this.deviceMeta.total,
          icon: 'mdi-developer-board',
        },
        // {
        //   text: 'Online Device',
        //   value: '-',
        //   icon: 'mdi-link-variant',
        // },
        // {
        //   text: 'Offline Device',
        //   value: '-',
        //   icon: 'mdi-link-variant-off',
        // },
      ];
    },

    devicesPath() {
      if (this.$store.getters.user.is_superadmin) {
        return {
          path: '/devices',
          query: {
            organization_id: this.organizationId || null,
          },
        };
      }
      return '/devices';
    },
  },

  methods: {
    underscoreToSpace,

    async getDevices(page = 1) {
      this.loading = true;
      try {
        const result = await this.$api.getDevices({
          organization_id: this.organizationId,
          page,
        });
        this.devices = result.data.data;
        this.deviceMeta = result.data.meta;
      } catch (error) {
        this.$toast.error('Failed Get Devices');
      }
      this.loading = false;
    },
  },
};
</script>
