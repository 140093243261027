<template>
  <v-card :loading="loading">
    <v-skeleton-loader
      :loading="loading"
      type="list-item-avatar-two-line"
      max-width="25%"
    >
      <v-list two-line>
        <v-list-item
          v-for="(u, index) in userItems"
          :key="index"
        >
          <v-list-item-avatar>
            <v-icon
              color="grey lighten-1"
              v-text="u.icon"
            />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-subtitle v-text="u.text" />
            <v-list-item-title v-text="u.value" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-skeleton-loader>

    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left min-width-10">
              Name
            </th>
            <th class="text-left">
              Email
            </th>
            <th class="text-left">
              Level
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="user in users.slice(0,5)"
            :key="user.id"
          >
            <td>{{ user.name }}</td>
            <td>{{ user.email }}</td>
            <td>
              <v-chip
                v-if="user.is_superadmin"
                small
                class="ma-2"
                color="indigo"
                text-color="white"
              >
                Superadmin
              </v-chip>
              <v-chip
                v-else-if="user.is_organization_admin"
                small
                class="ma-2"
                color="warning"
                text-color="white"
              >
                Admin
              </v-chip>
              <v-chip
                v-else
                small
                class="ma-2"
                color="grey"
                text-color="white"
              >
                User
              </v-chip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-card-actions>
      <v-btn
        block
        text
        color="primary"
        :to="usersPath"
      >
        View All Users
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'OrganizationDetailUser',
  props: {
    organizationId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      users: [],
      userMeta: {},
    };
  },

  computed: {
    userItems() {
      return [
        {
          text: 'Total User',
          value: this.userMeta.total,
          icon: 'mdi-account-group',
        },
      ];
    },

    usersPath() {
      if (this.$store.getters.user.is_superadmin) {
        return {
          path: '/users',
          query: {
            organization_id: this.organizationId || null,
          },
        };
      }
      return '/users';
    },
  },

  methods: {
    async getUsers(page = 1) {
      this.loading = true;
      try {
        const result = await this.$api.getUsers({
          organization_id: this.organizationId,
          page,
        });
        this.users = result.data.data;
        this.userMeta = result.data.meta;
      } catch (error) {
        this.$toast.error('Failed Get Users');
      }
      this.loading = false;
    },
  },
};
</script>
